<template>
	<div class="root">
		<div class="mask">
			<el-image
				@click.native="$router.push('/index')"
				fit="cover"
				:src="logoUrl"
				alt="加载失败"
			></el-image>
			<div class="register" style="margin-top: 20px;margin-left: 20px">
				<el-container>
					<el-header class="register-step">
						<el-steps :active="active">
							<el-step
								title="身份验证"
								icon="el-icon-phone"
							></el-step>
							<el-step
								title="填写个人信息"
								icon="el-icon-edit"
							></el-step>
							<el-step
								title="注册成功"
								icon="el-icon-success"
							></el-step>
						</el-steps>
					</el-header>
					<el-main class="register-form">
						<el-form
							:model="rulePhoneForm"
							:rules="phoneRules"
							ref="rulePhoneForm"
							v-if="active == 0"
							label-width="100px"
						>
							<el-form-item
								style="margin-top:5em"
								label="手机号"
								prop="phone"
							>
								<el-input
									size="small"
									prefix-icon="el-icon-phone-outline"
									placeholder="请输入手机号码"
									v-model="rulePhoneForm.phone"
								></el-input>
							</el-form-item>
							<el-form-item label="身份证号" prop="idCard">
								<el-input
									size="small"
									prefix-icon="el-icon-user"
									placeholder="请输入学生的身份证号码"
									v-model="rulePhoneForm.idCard"
								></el-input>
							</el-form-item>
							<!-- <el-form-item
								label="验证码"
								prop="validCode"
								class="valid-code"
							>
								<el-input
									size="small"
									v-model="rulePhoneForm.validCode"
								></el-input>
								<el-button
									size="mini"
									round
									:disabled="isAble"
									@click="getValidateCode"
									><span v-if="second < 60"
										>重新获取{{ second }}s</span
									><span v-else>获取验证码</span></el-button
								>
							</el-form-item> -->
							<el-form-item class="next-step" align="center">
								<el-button
									type="primary"
									:loading="loading"
									@click="submitForm('rulePhoneForm')"
									v-if="active < 2"
									>下一步</el-button
								>
							</el-form-item>
                            <span class="hint" v-if="active < 2">
							<h3>温馨提示：</h3>
							<p>
								1.一个孩子只能对应一个手机号码，手机号码作为乐学通登录账号即用户名。
							</p>
							<p>2.身份证号请如实填写学生的身份证号码。</p>
						</span>
						</el-form>

						<el-form
							:model="ruleInfoForm"
							:rules="infoRules"
							ref="ruleInfoForm"
							v-if="active == 1"
							label-width="90px"
						>
							<el-form-item label="姓名" prop="studentName">
								<el-input
									size="small"
									:disabled="nameDisabled"
									prefix-icon="el-icon-user"
									placeholder="请填写姓名"
									v-model="ruleInfoForm.studentName"
								></el-input>
							</el-form-item>
							<el-form-item label="性别" prop="sex">
								<el-radio-group
									v-model="ruleInfoForm.sex"
									:disabled="sexDisabled"
								>
									<el-radio label="1">男</el-radio>
									<el-radio label="0">女</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="学校" prop="deptId">
								<el-select
									size="small"
									placeholder="请选择学校"
									v-model="ruleInfoForm.deptId"
									:disabled="deptDisabled"
								>
									<el-option
										v-for="(item, index) in schoolList"
										:key="index"
										:label="item.deptName"
										:value="item.id"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="年级" prop="gradeName">
								<el-select
									size="small"
									placeholder="请选择年级"
									v-model="ruleInfoForm.gradeName"
									:disabled="gradeDisabled"
								>
									<el-option
										v-for="(item, index) in gradeList"
										:key="index"
										:label="item.dictValue"
										:value="item.dictKey"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="密码" prop="pass">
								<el-input
									size="small"
									:type="flag ? 'password' : 'text'"
									v-model="ruleInfoForm.pass"
									autocomplete="off"
									prefix-icon="el-icon-key"
									placeholder="请输入密码"
									><i
										slot="suffix"
										:class="
											flag
												? 'el-icon-minus'
												: 'el-icon-view'
										"
										style="margin-top: 8px; font-size: 18px"
										autocomplete="auto"
										@click="flag = !flag"
									/>
								</el-input>
							</el-form-item>
							<el-form-item label="确认密码" prop="checkPass">
								<el-input
									size="small"
									:type="flag1 ? 'password' : 'text'"
									v-model="ruleInfoForm.checkPass"
									autocomplete="off"
									prefix-icon="el-icon-key"
									placeholder="请再次输入密码"
								>
									<i
										slot="suffix"
										:class="
											flag1
												? 'el-icon-minus'
												: 'el-icon-view'
										"
										style="margin-top: 8px; font-size: 18px"
										autocomplete="auto"
										@click="flag1 = !flag1"
								/></el-input>
							</el-form-item>
							<el-form-item
								class="register-finish"
								align="center"
							>
								<el-button
									type="primary"
									:loading="loading"
									@click="submitForm('ruleInfoForm')"
									v-if="active < 2"
									>注册</el-button
								>
							</el-form-item>
						</el-form>
						<el-form v-if="active == 2" class="success">
							<i class="el-icon-circle-check">账号注册成功!</i>
							<div class="login" @click="$router.push('/login')">
								<i class="el-icon-right"></i>现在登录
							</div>
						</el-form>
					</el-main>
				</el-container>
			</div>
		</div>
	</div>
</template>

<script>
import logo from '@/assets/img/logo2.svg'
import {
	validateUserByPhone_api,
	registerSchool_api,
	registerGrade_api,
	registerSubmit_api,
} from '@/api/user.js'
export default {
	name: 'register',
	data() {
		//验证身份证号码
		const validateidCard = (rule, value, callback) => {
			if (
				/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/.test(
					value
				) == false
			) {
				callback(new Error('请如实填写学生的身份证号码'))
			} else {
				callback()
			}
		}
		//验证手机号码
		const validatephone = (rule, value, callback) => {
			// if (
			// 	/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
			// 		value
			// 	) == false
			// ) {
			// 	callback(new Error('请输入正确的手机号码'))
			// } else {
			// 	callback()
			// }
      if(/^1\d{10}$/.test(value) == false){
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
		}
		//第一次密码校验
		const validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'))
			} else {
				if (this.ruleInfoForm.pass !== '') {
					this.$refs.ruleInfoForm.validateField('checkPass')
				}
				callback()
			}
		}
		//第二次密码校验
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.ruleInfoForm.pass) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			isAble: false, //验证码按钮是否可点击
			second: 60, //验证码再次获取时间
			flag: true, // 第一个密码框
			flag1: true, // 第二个密码框
			logoUrl: logo, //logo图片
			schoolList: [], //学校列表
			gradeList: [], //年级列表
			isDisabled: false,
			nameDisabled: false, //通过身份证号获取的学生信息后，就不可修改
			sexDisabled: false,
			deptDisabled: false,
			gradeDisabled: false,
			loading: false, //下一步和注册按钮的等待
			active: 0,
			rulePhoneForm: {
				phone: '',
				idCard: '',
				// validCode: '',
				// validCodeId: '',
			},
			ruleInfoForm: {
				studentName: '',
				sex: '',
				deptId: '',
				gradeName: '',
				pass: '',
				checkPass: '',
			},
			phoneRules: {
				phone: [
					{
						required: true,
						message: '请输入手机号',
						trigger: 'blur',
					},
					{ validator: validatephone, trigger: 'blur' },
				],
				idCard: [
					{
						required: true,
						message: '请输入身份证号码',
						trigger: 'biur',
					},
					{ validator: validateidCard, trigger: 'blur' },
				],
				// validCode: [
				// 	{
				// 		required: true,
				// 		message: '请输入验证码',
				// 		trigger: 'blur',
				// 	}
				// ],
			},
			infoRules: {
				studentName: [
					{
						required: true,
						message: '请输入姓名',
						trigger: 'blur',
					},
				],
				sex: [
					{
						required: true,
						message: '请选择性别',
						trigger: 'change',
					},
				],
				deptId: [
					{
						required: true,
						message: '请选择学校',
						trigger: 'change',
					},
				],
				gradeName: [
					{
						required: true,
						message: '请选择年级',
						trigger: 'change',
					},
				],
				pass: [
					{
						required: true,
						message: '请输入密码',
						trigger: 'blur',
					},
					{
						min: 6,
						max: 16,
						message: '长度在 6 到 16 个字符',
						trigger: 'blur',
					},
					{ validator: validatePass, trigger: 'blur' },
				],
				checkPass: [
					{
						required: true,
						message: '请再次输入密码',
						trigger: 'blur',
					},
					{
						min: 6,
						max: 16,
						message: '长度在 6 到 16 个字符',
						trigger: 'blur',
					},
					{
						validator: validatePass2,
						trigger: 'blur',
						required: true,
					},
				],
			},
		}
	},
	methods: {
		//获取学校信息
		async getSchool() {
			let { code, msg, data } = await registerSchool_api({
				tenantId: this.$tenantId,
			})
			if (code == 200) {
				this.schoolList = data
			}
		},
		//获取年级
		async getGrade() {
			let { code, msg, data } = await registerGrade_api({
				code: 'target_generation',
			})
			if (code == 200) {
				this.gradeList = data
			}
		},
		async submitMessage() {
			this.isAble = true
			//检查手机号是否已经存在账号信息，无法注册
			let { code, data, msg } = await validateUserByPhone_api({
				tenantId: this.$tenantId,
				account: this.rulePhoneForm.phone,
				idCard: this.rulePhoneForm.idCard,
			})
			if (code == 200) {
				this.active++
				if (data.studentName) {
					this.nameDisabled = true //有姓名，不能修改姓名
					//自动填充个人信息
					this.ruleInfoForm.studentName = data.studentName
				}
				if (data.sex) {
					this.sexDisabled = true //有性别，不能修改性别
					//自动填充个人信息
					this.ruleInfoForm.sex = data.sex
				}
				if (data.schoolName) {
					this.deptDisabled = true //有场景，不能修改场景
					//自动填充个人信息
					this.ruleInfoForm.deptId = data.schoolName
				}
				if (data.gradeName) {
					this.gradeDisabled = true //有年级，不能修改年级
					//自动填充个人信息
					this.ruleInfoForm.gradeName = data.gradeName
				}
				this.loading = false
			} else {
				this.loading = false
				this.$message.error(msg)
			}
			// if (!data) {
			// this.$message({
			// 	type: 'error',
			// 	message: '账号已经被注册',
			// })
			// setTimeout(() => {
			// 	this.isAble = false
			// }, 1000)
			// this.isAble = true
			// return false
			// }
			this.$store.commit('updateRegisterPhone', this.rulePhoneForm.phone)
			// let params = {
			// 	code: 'ali-validate',
			// 	tenantId: this.$tenantId,
			// 	phone: this.rulePhoneForm.phone,
			// }
			// sendValidate_api(params).then((res) => {
			// 	if (res.code == 200) {
			// 		//验证码发送60S以后才能再次发送验证码
			// 		setTimeout(() => {
			// 			this.isAble = false
			// 		}, 60000)
			// 		let tim = setInterval(() => {
			// 			this.second--
			// 			if (this.second == 0) {
			// 				//当num变为0的时候，通过 clearInterval()结束倒计时
			// 				clearInterval(tim)
			// 				this.second = 60
			// 			}
			// 		}, 1000)
			// 		this.rulePhoneForm.validCodeId = res.data.id
			// 		this.$message({
			// 			type: 'success',
			// 			message: '验证码已发送，请注意查收',
			// 		})
			// 	} else {
			// 		this.$message({
			// 			type: 'error',
			// 			message: res.msg,
			// 		})
			// 	}
			// }),
			// 	(error) => {
			// 		this.$message.error(res.msg)
			// 	}
		},
		//手机号码及验证码提交
		// async validDatePhone() {
		// 	let { code, data, msg } = await validateMessage_api({
		// 		code: 'ali-validate',
		// 		id: this.rulePhoneForm.validCodeId,
		// 		value: this.rulePhoneForm.validCode,
		// 	})
		// 	if (code == 200) {
		// 		this.active++
		// 	} else {
		// 		this.$message({
		// 			type: 'error',
		// 			message: msg,
		// 		})
		// 	}
		// },
		//个人信息提交
		async validDatePerson() {
			//根据学校id获取学校name
			let school = this.schoolList.find(
				(item) => item.id == this.ruleInfoForm.deptId
			)
			let { code, msg, data } = await registerSubmit_api({
				tenantId: this.$tenantId,
				phone: this.rulePhoneForm.phone,
				studentName: this.ruleInfoForm.studentName,
				sex: this.ruleInfoForm.sex,
				deptId: this.ruleInfoForm.deptId,
				schoolName: school.deptName,
				gradeName: this.ruleInfoForm.gradeName,
				pass: this.ruleInfoForm.pass,
				idCard: this.rulePhoneForm.idCard,
			})
			if (code == 200) {
				this.loading = false
				this.active++
			} else {
				this.loading = false
				this.$message({
					type: 'error',
					message: msg,
				})
			}
		},
		submitForm(formName) {
			// if (
			// 	this.active == 0 &&
			// 	this.rulePhoneForm.phone != this.$store.state.registerPhone
			// ) {
			// 	this.$message({
			// 		type: 'error',
			// 		message: '验证码无效',
			// 	})
			// 	return false
			// }
			// if (this.isDisabled)
			// 	return this.$message.error('请勿频繁点击,正在提交信息')
			// this.isDisabled = true
			// setTimeout(() => {
			// 	this.isDisabled = false
			// }, 2000)

			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loading = true
					if (this.active == 0) {
						// this.validDatePhone()
						this.submitMessage()
					} else if (this.active == 1) {
						this.validDatePerson()
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
	},
	mounted() {
		this.getSchool()
		this.getGrade()
	},
}
</script>

<style lang="less" scoped>
@btn-login: rgb(65, 159, 247);
.root {
	height: 100%;
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: url('../../assets/img/loginBackground.png');
		background-size: 100% 100%;
		.el-image {
			cursor: pointer;
			width: 500px;
			height: 150px;
			position: absolute;
			top: 100px;
			left: 150px;
		}
		.register {
			border: rgb(212, 212, 212) solid 0.1px;
			border-radius: 5px;
			box-shadow: 2px 2px 5px #c7c7c7;
			width: 45vw;
			height: 60vh;
			position: absolute;
			top: 50%;
			left: 10vw;
			transform: translateY(-40%);
			font-size: 14px;
			padding: 10px;
			.register-step {
				margin-top: 3rem;
			}
			.register-form {
				width: 24rem;
				margin: 0 auto;
				.hint {
					font-size: 14px;
					width: 300px;
					line-height: 1.8em;
					p {
						text-indent: 2em;
					}
				}
				.el-input {
					/deep/.el-input__inner {
						border-radius: 20px;
					}
				}
				.el-button {
					margin: 3px 0;
				}
				.valid-code {
					/deep/.el-form-item__content {
						display: flex;
						flex-direction: row;
						justify-content: center;
						.el-input {
							flex: 1;
							margin-right: 10px;
						}
						.el-button {
							width: 10em;
						}
					}
				}
				.next-step {
					/deep/.el-form-item__content {
						margin-right: 80px;
					}
				}
				.register-finish {
					/deep/.el-form-item__content {
						margin-right: 90px;
					}
				}
				.success {
					height: 300px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					font-size: 30px;
					.i {
						margin-right: 30px;
						font-size: 50px;
						color: #409eff;
					}
					.login {
						margin-top: 1.5em;
						font-size: 25px;
						&:hover {
							color: @btn-login;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
</style>
